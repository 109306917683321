import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FleetService} from '../../../../shared/services/fleet.service';
import {CalculationService} from '../../../../shared/services/calculation.service';
import {HelperService} from '../../../../shared/services/helper.service';
import {ICalculation} from '../../../../shared/interfaces/calculation.interface';
import {CalculationRestService} from '../../../../shared/services/rest-services/calculation-rest.service';

@Component({
  selector: 'app-fixed-price-calculation',
  templateUrl: './fixed-price-calculation.component.html'
})
export class FixedPriceCalculationComponent implements OnInit, OnDestroy {
  public fixedPriceCalculations: ICalculation[] = [];
  public fixedPriceSubscription: Subscription;
  public closeResult: string;


  constructor(public calculationService: CalculationService,
              public calculationRestService: CalculationRestService,
              private modalService: NgbModal,
              public helperService: HelperService,
              public fleetService: FleetService) {
  }

  ngOnInit() {
    this.fixedPriceSubscription = this.calculationService.calculations.subscribe(
      (data) => {
        if (data) {
          this.fixedPriceCalculations = data;
          this.fixedPriceCalculations.sort((a, b) => {
            return a.sortNo - b.sortNo;
          });
        } else {
          this.fixedPriceCalculations = [];
        }
      });
  }

  ngOnDestroy() {
    this.fixedPriceSubscription.unsubscribe();
  }

  public csvDownload(): void {
    this.calculationRestService.getSCVDownload(this.fleetService.currentFleetId, 'FIXED_PRICE')
      .subscribe((response: ArrayBuffer) => {
        const filename = 'fixed-prices.csv';
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response]));
        link.setAttribute('download', filename);
        link.click();
      });
  }

  public deleteCalculation(id: number) {
    this.calculationRestService.deleteCalculation(id).subscribe(data => {
      this.calculationService.getCalculationsByFleetAndType(this.fleetService.fleetId.value, 'FIXED_PRICE');
      this.calculationService.resetCalculationIds();
    });
  }


  public open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, () => {
      this.closeResult = `Dismissed`;
    });
  }

}
